import React from 'react';
import MainLayout from '../components/MainLayout';
import Row from 'react-bootstrap/Row';
import { Link } from 'gatsby';
import Col from 'react-bootstrap/Col';
import Logo from '../components/menu/Logo';
import MenuBurgerButton from '../components/menu/MenuBurgerButton';
import '../css/TeamMember.css';

/**
 * Team Member page template
 */
class TeamMember extends React.Component {

	render() {

		let previousMember = '';
		if (this.props.pageContext.previousMember) {
			previousMember = (
				<Link to={this.props.pageContext.previousMember.elements.id.value} className="teammember-previous-member">
					<div className="emg-arrow-left" /> {this.props.pageContext.previousMember.elements.name.value}
				</Link>
			);
		}

		let nextMember = '';
		if (this.props.pageContext.nextMember) {
			nextMember = (
				<Link to={this.props.pageContext.nextMember.elements.id.value} className="teammember-next-member teammember-previous-member">
					{' '}
					{this.props.pageContext.nextMember.elements.name.value}
					<div className="emg-arrow-right" />
				</Link>
			);
		}

		return (
			<MainLayout scrollSnap={false} noMenu={true} activeItem={0} className="teammember-container">
				<Logo key="logo-intro" className="" />
				<MenuBurgerButton key="burger-btn-intro" className="" />
				{/* TEAM MEMBER PICTURE */}
				<div
					className="hidden-small-version teammember-picture"
					style={{ backgroundImage: 'url(' + this.props.pageContext.data.elements.picture.value[0].url + ')' }}
				/>

				<div className="teammember-darkpolygon hidden-small-version">
					<svg style={{ width: '100%', height: '100%' }} viewBox="0 0 100 100" preserveAspectRatio="none">
						<polygon points="0,0 50,0 70,100 0,100" fill="#141417" />
					</svg>
				</div>

				{/* BACK BTN */}
				<Row className="teammember-padding">
					<Col xs={{ span: 10, offset: 1 }}>
						<Link to={this.props.pageContext.parentUrl + '#about-team'} className="teammember-back">
							<div className="emg-arrow-left" /> BACK
						</Link>
					</Col>
				</Row>

				{/* TEAM MEMBER DETAILS */}
				<Row className="teammember-row">
					<Col xs={{ span: 10, offset: 1 }} lg={{ span: 5, offset: 1 }} className="teammember-col">
						<div className="teammember-infos">
							<div className="emg-title">{this.props.pageContext.data.elements.name.value}</div>
							<div className="teammember-role emg-text">{this.props.pageContext.data.elements.role.value}</div>
							<div className="emg-text" dangerouslySetInnerHTML={{ __html: this.props.pageContext.data.elements.details.value }} />
							<img
								className="hidden-big-version teammember-picture"
								src={this.props.pageContext.data.elements.picture.value[0].url}
								alt={this.props.pageContext.data.elements.name.value}
							/>
						</div>

						{/* TEAM MEMBER LINKS (PREVIOUS & NEXT) */}
						<div className="teammember-links hidden-small-version">
							{previousMember}
							{nextMember}
						</div>
					</Col>
					<Col xs={{ span: 5 }} />
				</Row>
			</MainLayout>
		);
	}
}

export default TeamMember;
