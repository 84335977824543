import React from 'react';
import { Link } from 'gatsby';
import { StaticQuery, graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../css/menu/Logo.css';

/**
 * Logo component (used on homepage sections for exemple)
 */
class Logo extends React.Component {

	render() {
		return (
			<StaticQuery
				query={logoQuery}
				render={(data) => (
					<Container className={this.props.className + ' logo'} fluid>
						<Row>
							<Col xs={{ offset: 1 }} className="col-md-auto">
								<Link to="/home">
									<img src={data.allKontentItemMenu.edges[0].node.elements.logo.value[0].url} width="200" alt="EMG logo" />
								</Link>
							</Col>
						</Row>
					</Container>
				)}
			/>
		);
	}
}

export default Logo;

// Graphql request to get logo url
export const logoQuery = graphql`
	query Logo {
		allKontentItemMenu {
			edges {
				node {
					elements {
						logo {
							value {
								url
							}
						}
					}
				}
			}
		}
	}
`;
